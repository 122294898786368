import React, { useEffect, useState, useRef } from 'react';

export type SearchDataType = [string, string, (value: string, callback?: (value: string) => void) => void, (value: string) => void];

export function useSearchDebounce(initialInput = '', delay = 300, searchCallback?: (value: string) => void, queryStrParam?: string): SearchDataType {
	const [search, setSearch] = useState<string>(initialInput);
	const [inputValue, setInputValue] = useState<string>(initialInput);
	const timeoutId = useRef<number | null>(null);

	const updateSearch = (value: string, callback?: (value: string) => void) => {
		setSearch(value);
		callback?.(value);
		searchCallback?.(value);

		if (queryStrParam) {
			const url = new URL(window.location.href);
			if (value) url.searchParams.set(queryStrParam, value);
			else url.searchParams.delete(queryStrParam);
			window.history.replaceState({}, '', url.toString());
		}
	};

	const updateData = (value: string, callback?: (value: string) => void) => {
		setInputValue(value);
		if (timeoutId.current) clearTimeout(timeoutId.current);
		timeoutId.current = window.setTimeout(() => {
			updateSearch(value, callback);
		}, delay);
	};

	useEffect(() => {
		if (queryStrParam) {
			const url = new URL(window.location.href);
			const queryValue = url.searchParams.get(queryStrParam);
			if (queryValue) {
				setInputValue(queryValue);
				setSearch(queryValue);
			}
		}

		return () => {
			if (timeoutId.current) clearTimeout(timeoutId.current);
			if (queryStrParam) {
				const url = new URL(window.location.href);
				url.searchParams.delete(queryStrParam);
				window.history.replaceState({}, '', url.toString());
			}
		};
	}, [queryStrParam]);

	return [
		inputValue,
		search,
		updateData,
		(value: string) => {
			setInputValue(value);
			updateSearch(value);
		},
	];
}
